// 课程管理
import menuMain from '@/components/main/mainLevelOne';

const curriculumRouter = {
  path: '/curriculumCenter',
  component: menuMain,
  redirect: '/courseManage',
  name: 'curriculumCenter',
  meta: {
    title: '学习中心',
    icon: 'exam',
  },
  children: [
    {
      path: '/courseLabel',
      component: () => import('@/views/curriculumCenter/LabelManage.vue'),
      name: 'courseLabel',
      meta: {
        title: '课程标签管理',
      },
    },
    {
      path: '/courseManage',
      component: () => import('@/views/curriculumCenter/CurriculumManage.vue'),
      name: 'courseManage',
      meta: {
        title: '课件管理',
      },
    },
    {
      path: '/courseAdd',
      component: () => import('@/views/curriculumCenter/CourseAdd.vue'),
      name: 'courseAdd',
      meta: {
        title: '新增课件',
        belongTo: '课件管理',
        belongPath: '/courseManage',
      },
    },
    {
      path: '/courseEdit',
      component: () => import('@/views/curriculumCenter/CourseAdd.vue'),
      name: 'courseEdit',
      meta: {
        title: '修改课件',
        belongTo: '课件管理',
        belongPath: '/courseManage',
      },
    },
    {
      path: '/courseDetail',
      component: () => import('@/views/curriculumCenter/CourseDetail.vue'),
      name: 'courseDetail',
      meta: {
        title: '查看课件',
        belongTo: '课件管理',
        belongPath: '/courseManage',
      },
    },
    {
      path: '/courseAssign',
      component: () => import('@/views/curriculumCenter/CurriculumDistribution.vue'),
      name: 'courseAssign',
      meta: {
        title: '课程分配',
      },
    },
    {
      path: '/courseProgress',
      component: () => import('@/views/curriculumCenter/SpeedOfProgress.vue'),
      name: 'courseProgress',
      meta: {
        title: '课程进度',
      },
    },
    {
      path: '/publicCourse',
      component: () => import('@/views/curriculumCenter/publicCourse/list.vue'),
      name: 'publicCourse',
      meta: {
        title: '公开课管理',
      },
    },
    {
      path: '/publicCourse_distribution',
      component: () => import('@/views/curriculumCenter/publicCourse/distribution.vue'),
      name: 'publicCourse_distribution',
      meta: {
        title: '公开课分配',
      },
    },
    // {
    //   path: '/distributionDetail',
    //   component: () => import('@/views/curriculumCenter/publicCourse/distributionDetail.vue'),
    //   name: 'distributionDetail',
    //   meta: {
    //     title: '查看课件',
    //     belongTo: '公开课分配',
    //     belongPath: '/publicCourse_distribution',
    //   },
    // },
    {
      path: '/publicCourse_studyProgress',
      component: () => import('@/views/curriculumCenter/publicCourse/studyProgress.vue'),
      name: 'publicCourse_studyProgress',
      meta: {
        title: '公开课课程进度',
      },
    },
    {
      path: '/readRecord',
      component: () => import('@/views/curriculumCenter/publicCourse/readRecord.vue'),
      name: 'readRecord',
      meta: {
        title: '阅读记录',
        belongTo: '公开课管理',
        belongPath: '/publicCourse',
      },
    },
    {
      path: '/publicCourseAdd',
      component: () => import('@/views/curriculumCenter/publicCourse/add.vue'),
      name: 'publicCourseAdd',
      meta: {
        title: '新增公开课',
        belongTo: '公开课管理',
        belongPath: '/publicCourse',
      },
    },
    {
      path: '/publicCourseUpdate',
      component: () => import('@/views/curriculumCenter/publicCourse/add.vue'),
      name: 'publicCourseUpdate',
      meta: {
        title: '修改公开课',
        belongTo: '公开课管理',
        belongPath: '/publicCourse',
      },
    },
    {
      path: '/publicCourseDetail',
      component: () => import('@/views/curriculumCenter/publicCourse/detail.vue'),
      name: 'publicCourseDetail',
      meta: {
        title: '查看公开课',
        belongTo: '公开课管理',
        belongPath: '/publicCourse',
      },
    },
  ],
};

export default curriculumRouter;
