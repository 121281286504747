import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BigNumber } from 'bignumber.js';
import { VueJsonp } from 'vue-jsonp';
import Svg from 'vue2-svg-icon/Icon.vue';
import VueDraggableResizable from 'vue-draggable-resizable';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import './styles/resetElUi.scss';

import ExcutePanel from '@/L_components/excutePanel1';
import MessageTip from '@/L_components/MessageTip';

import './permission'; // 实际场景时候需要这个路由权限限制
import * as tools from '@/utils/tools';
import * as statusMaps from '@/utils/statusMap';

Vue.config.productionTip = false;

Vue.prototype.$elsize = 'small';
Vue.prototype.$tableBtnSize = 'small';
Vue.prototype.$tableHeaderStyle = { background: '#fafafa', fontWeigth: 'normal' };
// ,borderBottom: 'none'
Vue.prototype.$BigNumber = BigNumber;
Vue.prototype.$tools = tools;
Vue.prototype.$statusMaps = statusMaps;

const UPLOAD_URL = process.env.VUE_APP_UPLOAD_BASE_URL;
Vue.prototype.$UPLOAD_URL = UPLOAD_URL;
const BASE_URL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$BASE_URL = BASE_URL;

Vue.use(ElementUI);
Vue.use(MessageTip);
Vue.use(ExcutePanel);
Vue.use(VueJsonp);
Vue.component('svg-icon', Svg);
Vue.component('vue-draggable-resizable', VueDraggableResizable);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
