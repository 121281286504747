<template>
  <div>
    <el-badge :hidden="$store.state.user.remindCount === 0" :value="$store.state.user.remindCount" :max="99">
      <i class="el-icon-message-solid remind-icon remind-badge" @click="linkTo"></i>
    </el-badge>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link" style="color: #fff; cursor: pointer">
        <img v-if="$store.getters.avatar" :src="$UPLOAD_URL + $store.state.user.avatar" class="touixiang" height="20" width="20" />
        <img v-else src="@/assets/img/user.png" class="touixiang" height="20" width="20" />
        <span>&nbsp;{{ $store.getters.name }}</span>
        <i class="el-icon-caret-bottom"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="personnelCenter" icon="el-icon-user">个人中心<span style="margin-right: 20px"></span></el-dropdown-item>
        <el-dropdown-item command="loginOut" icon="el-icon-switch-button"
          >安全退出<span style="margin-right: 20px"></span
        ></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleCommand(command) {
      if (command === 'personnelCenter') {
        this.$router.push({ name: 'personnelCenter' });
      } else if (command === 'loginOut') {
        this.$store.dispatch('user/logout');
        this.$router.push({ name: 'login' });
      }
    },
    linkTo() {
      this.$router.push({ path: '/remindList' });
    },
  },
};
</script>
<style lang="less" scoped>
.remind-icon {
  transform: scale(1.4);
  margin-right: 16px;
}
.remind-badge {
  cursor: pointer;
}
.touixiang {
  vertical-align: middle;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
:deep(sup) {
  top: 12px !important;
  right: 24px !important;
}
</style>
