<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-if="levelList[0]?.parent" key="firstBread">
        <a class="belongLink" @click.prevent="handleLink(levelList[0].parent)">{{ levelList[0].parent.meta.title }}</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
        <a v-if="index === levelList.length - 1" class="no-redirect">{{ item.meta.title }}</a>
        <a v-else class="belongLink" @click.prevent="handleLink(item)">{{ item.meta.belongTo ? item.meta.belongTo : item.meta.title }} </a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from "path-to-regexp";

export default {
  name: "breadCrumb",
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let newLevelList = [];
      const path = this.$route.path.split("/").slice(1);
      const routers = this.$router.getRoutes();
      for (let index = 0; index < path.length; index++) {
        const item = path[index];
        const route = routers.find(pre => pre.name === item);
        if (route) {
          newLevelList.push(route);
        } else {
          newLevelList.push({ meta: { title: decodeURI(item) } });
        }
      }

      this.levelList = newLevelList;
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase();
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      try {
        const { params } = this.$route;
        var toPath = pathToRegexp.compile(path);
        return toPath(params);
      } catch (error) {
        console.error(error);
        return path;
      }
    },
    handleLink(item) {
      console.log("item :>> ", item);
      const { path, meta } = item;
      const { redirect, belongTo, belongPath } = meta;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      if (belongTo && belongPath) {
        this.$router.push(belongPath);
        return;
      }
      if (!path) return;
      this.$router.push(this.pathCompile(path));
    },
  },
};
</script>

<style lang="scss" scoped>
.lala {
  margin: 0 9px;
  font-weight: bold;
  color: #c0c4cc;
  font-style: normal;
  font-size: 20px;
  vertical-align: middle;
}
.app-breadcrumb.el-breadcrumb {
  // display: inline-block;
  font-size: 14px;
  line-height: 50px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
.belongLink {
  &:hover {
    cursor: pointer !important;
    color: #409eff !important;
  }
}
</style>
<style>
.el-breadcrumb__separator {
  font-weight: normal;
  color: #c0c4cc;
  font-size: 20px;
  vertical-align: middle;
}
</style>
