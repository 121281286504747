import { businessApi } from '@/utils/api';

// 在线考试-出题策略相关接口
export function ac_rule_add(data) {
  //添加
  return businessApi({
    url: '/rule/add',
    method: 'post',
    data,
  });
}

export function ac_rule_delete(data) {
  // 删除
  return businessApi({
    url: '/rule/delete',
    method: 'DELETE',
    data,
  });
}

export function ac_rule_get(params) {
  // 获取出题策略详情
  return businessApi({
    url: '/rule/get',
    method: 'GET',
    params,
  });
}

export function ac_rule_list(data) {
  //获取列表
  return businessApi({
    url: '/rule/list',
    method: 'post',
    data,
  });
}
export function ac_rule_update(data) {
  //修改
  return businessApi({
    url: '/rule/update',
    method: 'PUT',
    data,
  });
}

// 在线考试-分类相关接口
export function ac_category_add(data) {
  //添加分类
  return businessApi({
    url: '/category/add',
    method: 'post',
    data,
  });
}
export function ac_category_delete(params) {
  // 删除分类
  return businessApi({
    url: '/category/delete',
    method: 'DELETE',
    params,
  });
}
export function ac_category_tree(params) {
  // 获取列表
  return businessApi({
    url: '/category/tree',
    method: 'GET',
    params,
  });
}

export function ac_category_update(data) {
  //修改分类
  return businessApi({
    url: '/category/update',
    method: 'PUT',
    data,
  });
}
// 在线考试-分配考试相关接口
export function ac_allocationExam_add(data) {
  //分配考试 添加
  return businessApi({
    url: '/allocationExam/add',
    method: 'post',
    data,
  });
}
export function ac_allocationExam_delete(data) {
  //删除
  return businessApi({
    url: '/allocationExam/delete',
    method: 'DELETE',
    data,
  });
}
export function ac_allocationExam_list(data) {
  //获取列表
  return businessApi({
    url: '/allocationExam/list',
    method: 'post',
    data,
  });
}
// 获取分配详情
export function ac_allocationExam_detail(oe_id) {
  return businessApi({
    method: 'post',
    url: '/allocationExam/getDetail',
    params: { oe_id },
  });
}
// 分配考试编辑
export function ac_allocationExam_update(data) {
  return businessApi({
    method: 'post',
    url: '/allocationExam/update',
    data,
  });
}

// 已发布考试添加用户
export function ac_allocationExam_add_user(data) {
  return businessApi({
    url: '/allocationExam/addPublish',
    method: 'POST',
    data,
  });
}
// 在线考试-成绩相关接口
export function ac_score_export(data) {
  //导出
  return businessApi({
    url: '/score/export',
    method: 'post',
    data,
    responseType: 'blob',
  });
}
export function ac_score_list(data) {
  //获取列表
  return businessApi({
    url: '/score/list',
    method: 'post',
    data,
  });
}
// 在线考试-考试管理相关接口
export function ac_exam_add(data) {
  //添加
  return businessApi({
    url: '/exam/add',
    method: 'post',
    data,
  });
}
export function ac_exam_delete(data) {
  //删除
  return businessApi({
    url: '/exam/delete',
    method: 'DELETE',
    data,
  });
}

// 在线考试-考试管理相关接口
export function exportExam(data) {
  //添加
  return businessApi({
    url: '/exam/exportExam',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'arraybuffer',    
  });
}



export function getExam(params) {
  //获取考试
  return businessApi({
    url: '/exam/getExam',
    method: 'GET',
    params,
  });
}

export function ac_exam_get(params) {
  //获取考试详情
  return businessApi({
    url: '/exam/get',
    method: 'GET',
    params,
  });
}
export function ac_exam_getId(params) {
  //获取ID
  return businessApi({
    url: '/exam/getId',
    method: 'GET',
    params,
  });
}

export function ac_exam_list(data) {
  //获取列表
  return businessApi({
    url: '/exam/list',
    method: 'POST',
    data,
  });
}
export function ac_exam_update(data) {
  //修改
  return businessApi({
    url: '/exam/update',
    method: 'PUT',
    data,
  });
}
export function ac_exam_updateState(params) {
  //修改发布状态
  return businessApi({
    url: '/exam/updateState',
    method: 'PUT',
    params,
  });
}
export function ac_exam_getExamUser(params) {
  // 获取考试分配用户情况
  return businessApi({
    url: '/exam/getExamUser',
    method: 'GET',
    params,
  });
}

// 在线考试-题库管理相关接口
export function ac_question_add(data) {
  //添加
  return businessApi({
    url: '/question/add',
    method: 'POST',
    data,
  });
}

export function ac_question_delete(data) {
  //删除
  return businessApi({
    url: '/question/delete',
    method: 'DELETE',
    data,
  });
}

export function ac_question_download(params) {
  //下载Excel模板
  return businessApi({
    url: '/question/download',
    method: 'GET',
    params,
    responseType: 'blob',
  });
}

export function downloadExcelError(params) {
  //下载错误明细表格
  return businessApi({
    url: '/question/downloadExcel',
    method: 'GET',
    params,
    responseType: 'blob',
  });
}

export function ac_question_get(params) {
  //获取试题详情
  return businessApi({
    url: '/question/get',
    method: 'GET',
    params,
  });
}
export function ac_question_importExcel(data) {
  //导入
  return businessApi({
    url: '/question/importExcel',
    method: 'POST',
    data,
  });
}
export function ac_question_list(data) {
  //获取列表
  return businessApi({
    url: '/question/list',
    method: 'POST',
    data,
  });
}
export function ac_question_update(data) {
  //修改
  return businessApi({
    url: '/question/update',
    method: 'PUT',
    data,
  });
}

export function ac_examPaper_getExamNotice(params) {
  //获取考试须知
  return businessApi({
    url: '/examPaper/getExamNotice',
    method: 'GET',
    params,
  });
}
export function ac_examPaper_getExamPaperDetail(params) {
  //获取试卷详情
  return businessApi({
    url: '/examPaper/getExamPaperDetail',
    method: 'GET',
    params,
  });
}
export function ac_examPaper_getPaperInfoByExamId(params) {
  //根据考试ID获取试卷信息
  return businessApi({
    url: '/examPaper/getPaperInfoByExamId',
    method: 'GET',
    params,
  });
}
export function ac_examPaper_getPaperInfoByScoreId(params) {
  //根据成绩ID获取试卷信息
  return businessApi({
    url: '/examPaper/getPaperInfoByScoreId',
    method: 'GET',
    params,
  });
}
export function ac_examPaper_preview(data) {
  //预览
  return businessApi({
    url: '/examPaper/preview',
    method: 'POST',
    data,
  });
}

export function ac_examPaper_submitAnswer(data) {
  //提交答案
  return businessApi({
    url: '/examPaper/submitAnswer',
    method: 'POST',
    data,
  });
}
export function ac_myExam_end(params) {
  //结束考试
  return businessApi({
    url: '/myExam/end',
    method: 'POST',
    params,
  });
}
export function ac_myExam_getMyExamList(data) {
  //获取我的考试列表
  return businessApi({
    url: '/myExam/getMyExamList',
    method: 'POST',
    data,
  });
}
export function ac_myExam_getMyScoreList(data) {
  //获取我的成绩列表
  return businessApi({
    url: '/myExam/getMyScoreList',
    method: 'POST',
    data,
  });
}
export function ac_myExam_takeTheExam(params) {
  //参加考试
  return businessApi({
    url: '/myExam/takeTheExam',
    method: 'GET',
    params,
  });
}

//  项目管理-添加
export function project_add(data) {
  return businessApi({
    method: 'post',
    url: '/project/add',
    data,
  });
}
//  项目管理-删除
export function project_delete(xm_id) {
  return businessApi({
    method: 'post',
    url: '/project/deleteStatus',
    params: { xm_id },
  });
}
//  项目管理-导出
export function project_export(data) {
  return businessApi({
    method: 'post',
    url: '/project/export',
    data,
    responseType: 'blob',
  });
}
//  项目管理-导入
export function project_importExcel(data) {
  return businessApi({
    method: 'post',
    url: '/project/importExcel',
    data,
  });
}
//  项目管理-下载模板
export function project_download() {
  return businessApi({
    method: 'get',
    url: '/project/download',
    responseType: 'blob',
  });
}
//  项目管理-详情
export function project_getDetail(xm_id) {
  return businessApi({
    method: 'post',
    url: '/project/getDetail',
    params: { xm_id },
  });
}
//  项目管理-列表
export function project_list(data) {
  return businessApi({
    method: 'post',
    url: '/project/list',
    data,
  });
}
//  项目管理-修改
export function project_update(data) {
  return businessApi({
    method: 'post',
    url: '/project/update',
    data,
  });
}
//  项目管理-获取项目列表-表单提交用
export function project_getList() {
  return businessApi({
    method: 'post',
    url: '/project/getList',
  });
}
//  项目区域-添加
export function area_add(data) {
  return businessApi({
    method: 'post',
    url: '/area/add',
    data,
  });
}
//  项目区域-修改
export function area_update(data) {
  return businessApi({
    method: 'post',
    url: '/area/update',
    data,
  });
}
//  项目区域-删除
export function area_deleteStatus(id) {
  return businessApi({
    method: 'post',
    url: '/area/deleteStatus',
    params: { id },
  });
}
//  项目区域-下载excel模板
export function area_download() {
  return businessApi({
    method: 'get',
    url: '/area/download',
    responseType: 'blob',
  });
}
//  项目区域-详情
export function area_getDetail(id) {
  return businessApi({
    method: 'post',
    url: '/area/getDetail',
    params: { id },
  });
}
//  项目区域-列表
export function area_list(data) {
  return businessApi({
    method: 'post',
    url: '/area/list',
    data,
  });
}
//  项目区域-列表
export function area_getAreaLevel(data) {
  return businessApi({
    method: 'post',
    url: '/area/getAreaLevel',
    data,
  });
}

// 人事管理-添加
export function person_add(data) {
  return businessApi({
    method: 'post',
    url: '/person/add',
    data,
  });
}
// 人事管理-删除
export function person_delete(ids) {
  return businessApi({
    method: 'post',
    url: '/person/delete',
    data: ids,
  });
}
// 人事管理-导出
export function person_export(data) {
  return businessApi({
    method: 'post',
    url: '/person/export',
    data,
    responseType: 'blob',
  });
}
// 人事管理-导出考试\课程情况
export function person_exportType(data) {
  return businessApi({
    method: 'post',
    url: '/person/exportType',
    data,
    responseType: 'blob',
  });
}
// 人事管理-详情
export function person_getDetail(id) {
  return businessApi({
    method: 'post',
    url: '/person/getDetail',
    params: { id },
  });
}
// 人事管理-列表
export function person_list(data) {
  return businessApi({
    method: 'post',
    url: '/person/list',
    data,
  });
}
// 人事管理-禁用
export function person_no(ids) {
  return businessApi({
    method: 'post',
    url: '/person/no',
    data: ids,
  });
}
// 人事管理-启用
export function person_yes(ids) {
  return businessApi({
    method: 'post',
    url: '/person/yes',
    data: ids,
  });
}
// 人事管理-修改
export function person_update(data) {
  return businessApi({
    method: 'post',
    url: '/person/update',
    data,
  });
}
// 人事管理-获取考勤详情
export function person_getAttInOut(data) {
  return businessApi({
    method: 'post',
    url: '/person/getAttInOut',
    data,
  });
}
// 人事管理-获取参加的考试
export function person_getRsOeMyExam(data) {
  return businessApi({
    method: 'post',
    url: '/person/getRsOeMyExam',
    data,
  });
}
// 人事管理-获取课程
export function person_getRsKc(data) {
  return businessApi({
    method: 'post',
    url: '/person/getRsKc',
    data,
  });
}
// 人事管理-下载导入模板 1.本部 2.外派
export function person_download(type) {
  return businessApi({
    method: 'get',
    url: '/person/download',
    params: { type },
    responseType: 'blob',
  });
}
// 人事管理-导入人员
export function person_importExcel(type, data) {
  return businessApi({
    method: 'post',
    url: '/person/importExcel',
    data,
    params: { type },
  });
}

// 通用-发布对象使用-导入人员模板下载
export function person_modal_down() {
  return businessApi({
    url: '/download/download',
    method: 'get',
    responseType: 'blob',
  });
}
// 通用-发布对象使用-导入错误下载
export function person_modal_downloadExcel(file_name) {
  return businessApi({
    url: '/download /downloadExcel',
    method: 'get',
    params: { file_name },
    responseType: 'blob',
  });
}
// 通用-发布对象使用-导入人员
export function person_modal_importExcel(file) {
  return businessApi({
    url: '/download/importExcel',
    method: 'POST',
    data: { file },
  });
}

// 获取提醒设置列表
export function getRemindSettingList(data) {
  return businessApi({
    url: '/remind/list',
    method: 'POST',
    data,
  });
}

// 获取提醒列表
export function getRemindList(data) {
  return businessApi({
    url: '/remind/remindList',
    method: 'POST',
    data,
  });
}

// 新增提醒
export function addRemind(data) {
  return businessApi({
    url: '/remind/addConfig',
    method: 'POST',
    data,
  });
}

// 编辑提醒
export function updateRemind(data) {
  return businessApi({
    url: '/remind/updateConfig',
    method: 'POST',
    data,
  });
}

// 删除提醒
export function deleteRemind(data) {
  return businessApi({
    url: '/remind/deleteConfig',
    method: 'POST',
    data,
  });
}

// 提醒已读/未读
export function readRemind(id) {
  return businessApi({
    url: '/remind/getState',
    method: 'POST',
    params: { id },
  });
}

// 提醒全部已读/未读
export function readAllRemind() {
  return businessApi({
    url: '/remind/getAllState',
    method: 'POST',
  });
}

// 搜索历史
export function getSearchHistory(type) {
  return businessApi({
    url: '/search/list',
    method: 'POST',
    params: { type },
  });
}

// 增加搜索历史
export function addSearchHistory(data) {
  return businessApi({
    url: '/search/add',
    method: 'POST',
    data,
  });
}
