import { login, getLoginUserInfo, getSysUser, loginBySms } from '@/utils/api/user';
import { getRemindList } from '@/utils/api/administrativeCenter';
import { getToken, setToken, removeToken } from '@/utils/auth';
import router from '@/router';
import axios from 'axios';

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  phone: '',
  comment: '',
  userId: '',
  empId: '',
  userInfo: {},
  currentPosition: {},
  remindCount: 0,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_COMMENT: (state, comment) => {
    state.comment = comment;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId;
  },
  SET_EMP_ID: (state, empId) => {
    state.empId = empId;
  },
  SET_USER_INFO(state, data) {
    state.userInfo = data;
  },
  SET_CURRENT_POSITION(state, data) {
    state.currentPosition = data;
  },
  SET_REMIND_COUNT(state, data) {
    state.remindCount = data;
  },
};

const actions = {
  get_avatar({ commit }, avatar) {
    commit('SET_AVATAR', avatar);
  },
  get_comment({ commit }, comment) {
    commit('SET_COMMENT', comment);
  },
  // user login
  login({ commit }, userInfo) {
    const { phone, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ user_mobile: phone.trim(), user_pwd: password })
        .then(response => {
          if (response.state === 0) {
            const { data } = response;
            commit('SET_TOKEN', data.access_token);
            setToken(data.access_token);
            resolve();
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  loginBySms({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginBySms(userInfo)
        .then(response => {
          if (response.state === 0) {
            const { data } = response;
            commit('SET_TOKEN', data.access_token);
            setToken(data.access_token);
            resolve();
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info ,getSysUser
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getLoginUserInfo()
        .then(response => {
          if (response.state === 0) {
            if (!response.data) {
              reject('验证失败，请重新登录');
            }
            let userId = '';
            if (response.data && response.data.length > 0) {
              if (response.data[0].Type === 'login_user_id') {
                userId = response.data[0].Value;
                getSysUser({ user_id: userId }).then(res => {
                  const { data } = res;
                  if (!data) {
                    reject('验证失败，请重新登录');
                  }
                  const { user_name, user_avatar_url, user_mobile, user_comment, emp_id } = data;
                  if (res.state === 500) {
                    removeToken();
                    router.push('/login');
                  }
                  commit('SET_NAME', user_name);
                  commit('SET_AVATAR', user_avatar_url);
                  commit('SET_PHONE', user_mobile);
                  commit('SET_COMMENT', user_comment);
                  commit('SET_USER_ID', userId);
                  commit('SET_EMP_ID', emp_id);
                  commit('SET_USER_INFO', data);
                  resolve(data);
                });
              } else {
                reject('没找到userid');
              }
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getCurrentPosition() {
    return new Promise(() => {
      axios.get('https://apis.map.qq.com/ws/location/v1/ip?ip=111.206.145.41&key=XGWBZ-3JQ6X-3KO4V-TGB65-4SJT2-4GF5L').then(res => {
        console.log(res, 'rrrr');
      });
    });
  },

  // user logout
  logout({ commit }) {
    // console.log(commit,'commit是什么');
    commit('SET_TOKEN', '');
    commit('SET_NAME', '');
    commit('permission/SET_PERMISSION', [], { root: true });
    removeToken();
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_NAME', '');
      commit('permission/SET_PERMISSION', [], { root: true });
      removeToken();
      resolve();
    });
  },

  // dynamically modify permissions，没有这个场景，但是会切换角色，所以登录进来会清除
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token';

    commit('SET_TOKEN', token);
    setToken(token);

    // const { roles } = await dispatch('getInfo')

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', 'test', { root: true });
    // dynamically add accessible routes
    router.addRoutes(accessRoutes);
    router.history.setupListeners();

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true });
  },

  async getRemindCount({ commit }) {
    const params = {
      cond_list: [
        { key: 'user_id', operator: '=', value: state.userId },
        { key: 'state', operator: '=', value: '未读' },
      ],
      condition_where_type: 0,
      page_num: 1,
      page_size: 999999,
    };
    await getRemindList(params).then(res => {
      commit('SET_REMIND_COUNT', res.data.total || 0);
    });
  },

  setRemindCount({ commit }, count) {
    commit('SET_REMIND_COUNT', count);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
