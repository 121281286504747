import { getPermission } from '@/utils/api/user'
import router from '@/router'
const state = {
  permissionArr: [],
  sideBar: [],
  menu1Name: "",
  sideActive: ''
}

const mutations = {
  SET_PERMISSION: (state, arr) => {
    state.permissionArr = arr
  },
  SET_MENU1: (state, txt) => {
    state.menu1Name = txt
  },
  SET_BAR: (state, arr) => {
    state.sideBar = arr
  },
}

const actions = {
  menu1Name({ commit }, txt) {
    commit('SET_MENU1', txt)
  },
  sideBar({ commit }, arr) {
    commit('SET_BAR', arr)
  },
  permissionArr({ commit }, arr) {
    commit('SET_PERMISSION', arr)
  },
  getSideBar({ state, commit }) {
    let menuAll = state.permissionArr;
    let thisRoute = router.currentRoute;
    if (thisRoute.name) {
      let matched = thisRoute.matched.filter(
        (item) => item.meta && item.meta.title
      );
      if (matched[0]) {
        let firstMenu = matched[0].name;
        let sidebar = menuAll.find(item => item.name === firstMenu);
        if (sidebar) {
          commit('SET_BAR', sidebar.children);
        }
      }

    }
  },
  generateRoutes({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getPermission().then(response => {
        const { data } = response;
        if (data.module_list && data.module_list.length > 0) {

          const list = data.module_list[0].children

          const project = {
            id: "b5415ca7-2fe6-43af-894c-29dfs3fdf338d4",
            module_id: "babc4ca5-0d97-43c3-9ac5-34baa9a410a0",
            name: "projectArea",
            title: "项目分类",
            icon: "",
            seq: 3,
            upper_id: "b4514f17-6306-4d58-9585-98f826effc70",
            quick_mark: 0,
            children: []
          }
          const projectP = list.find(item => item.title === '项目管理')
          projectP.children.push(project)

          commit('SET_PERMISSION', list);
          dispatch('getSideBar'); // 第一次进来才走这里
          resolve(data.module_list)
        } else {
          reject('当前用户没有分配菜单')
        }
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
