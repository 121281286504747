import { Message } from 'element-ui';
/**
 ******** 工具函数
 ********
 ********
 */
export function copy(text, callback) {
  var tag = document.createElement('input');
  tag.setAttribute('id', 'cp_hgz_input');
  tag.value = text + '';
  document.getElementsByTagName('body')[0].appendChild(tag);
  document.getElementById('cp_hgz_input').select();
  document.execCommand('copy');
  document.getElementById('cp_hgz_input').remove();
  if (callback) {
    callback(text);
  }
}

/**
 * @author {pan.liu}
 * 节流 防止重复点击 ****先执行
 * eg: throttle(()=>{},500)
 * 2020-7-7
 */
export const throttle = (function () {
  const timer = null;
  let startTime = Date.now();
  return function (func, delay = 200, showTip = true) {
    const curTime = Date.now();
    const remaining = delay - (curTime - startTime);
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    if (remaining <= 0) {
      func.apply(context, args);
      startTime = Date.now();
    } else {
      // timer = setTimeout(func, remaining);
      showTip && Message({ type: 'warning', message: '操作太快' });
    }
  };
})();

/**
 * @author {pan.liu}
 * 防抖 防止重复响应 ****后执行
 * eg: debounce(()=>{},500)
 * 2020-11-16
 */
export const debounce = (function () {
  let timer = null;
  return function (func, delay = 200) {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
})();

/**
 * 文件下载
 * @author {pan.liu}
 * @param {blob} blobData
 * @param {string} filename 文件名
 * @param {function} successCallback 成功回调
 * 2020-7-7
 */
export const downloadFile = (blobData, filename, successCallback) => {
  const aLink = document.createElement('a');
  document.body.appendChild(aLink);
  aLink.style.display = 'none';
  aLink.href = window.URL.createObjectURL(blobData);
  aLink.setAttribute('download', filename);
  aLink.click();
  document.body.removeChild(aLink);
  successCallback && successCallback();
};

/**
 * 生成guid | 60bffed0-d4b4-19eb-326e-4735b305998e
 */
export const guid = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  function getGuid() {
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
  }
  return getGuid();
};

/**
 * 深克隆
 */
export const deepClone = data => {
  let res;
  if (Array.isArray(data)) {
    res = [];
    for (let i = 0; i < data.length; i++) {
      res.push(deepClone(data[i]));
    }
  } else if (Object.prototype.toString.call(data) === '[object Object]') {
    res = {};
    for (let key in data) {
      res[key] = deepClone(data[key]);
    }
  } else {
    res = data;
  }
  return res;
};

export const filterList = (target, key) => {
  let obj = {};
  target.map(it => {
    obj[it[key]] = it;
  });
  return Object.values(obj);
};
export const filterListByKeyVal = (obj, key, val) => {
  return obj.filter(item => {
    if (item[key] === val) {
      return item;
    }
  });
};
export const toFixedFix = (n, prec = 2) => {
  var k = Math.pow(10, prec);
  return '' + Math.round(n * k) / k;
};

