import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, setToken } from '@/utils/auth'

export default class BaseApi {
  host
  name
  service
  constructor(host, name) {
    if (!host) throw new Error('没有配置' + name);
    this.host = host;
    this.name = name;

    const service = axios.create({
      baseURL: `${host}/${name}`,
      timeout: 90000
    });

    this.service = service;

    service.interceptors.request.use(
      config => {
        if (store.getters.token) {
          // if (name === 'sys_api_xnql') {
          config.headers['Authorization'] = 'Bearer ' + getToken();
          // } else {
          // 	config.headers['token'] = 'Bearer ' + getToken();
          // }
        }
        return config
      },
      error => {
        console.log(error) // for debug
        return Promise.reject(error)
      }
    )

    service.interceptors.response.use(
      response => {
        if (response.request.responseType === 'blob') {
          return response
        } else {
          const res = response.data;
          if (res.msg && res.state !== 0) {
            if (res.state === 401) {
              Message({
                message: res.msg,
                type: 'error',
                duration: 5000,
                showClose: true
              })
              store.dispatch('user/resetToken')
              router.push('/login')
            }
            else if (res.state == 500) {
              // 若不希望使用message提示可在 request({unShowErrorTip: true})
              !response.config.unShowErrorTip && Message({
                message: res.msg,
                type: 'error',
                duration: 5000,
                showClose: true
              })
            } else {
              !response.config.unShowErrorTip && Message({
                message: res.msg,
                type: 'warning',
                duration: 5000,
                showClose: true
              })
            }
          }
          return res
        }
      },
      error => {
        if (error && error.response) {
          if (error.response.status === 401) {
            Message({
              showClose: true,
              message: '登录过期，请重新登录',
              type: 'error',
              duration: 1000,
            })
            store.dispatch('user/resetToken')
            setToken('');
            router.replace('/login')
          } else {
            Message({
              showClose: true,
              message: '系统报错，联系客服处理',
              type: 'error'
            })
          }
        }
        return Promise.reject(error)
      }
    )
  }

  axiosInstance() {
    return this.service;
  }
}

